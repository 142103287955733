<template>
 
  
  <div class="page">
    {{ t }}
  </div>  

</template>

<script setup>

definePageMeta({
  layout: "short",
  auth: false
})

const router = useRouter()

const t = ref(router.currentRoute.value.query.t)



 

onMounted(() => {
  if(import.meta.client){
    useAuth().setCookieToken(t.value)
    useAuth().refresh();
    document.location.href = "/"
  }
})

</script>


<style scoped>
.page{
  padding: 2rem;
} 
.actions{
  /* margin-top: 10px; */
}
.actions > *{
  margin-right: 10px;
}

.wrong{
  text-align: center;
}
</style>
